import { mapState } from 'vuex';
export default {
  name: "articleDetail",
  data() {
    return {
      data: {}
    };
  },
  computed: {
    ...mapState({
      device: state => state.device,
      language: state => state.language
    })
  },
  watch: {
    // $route: function(to, from) {
    // 	this.last = JSON.parse(this.$route.query.last)
    // 	if (this.$route.query.params) {
    // 		this.ruleForm = JSON.parse(this.$route.query.params)
    // 	}
    // }
  },
  created() {
    this.changeRoute();
    this.getData();
  },
  methods: {
    // 回到上一级
    reback() {
      this.$router.go(-1);
      // this.$router.push({
      // 	path: this.path
      // })
    },
    getData() {
      this.$http.post(this.$api.news_detail, {
        id: this.$route.query.id
      }).then(res => {
        if (res.code == 0) {
          this.data = res.data;
        }
      });
    },
    changeRoute(row, index) {
      // if(this.$store.state.language==3){
      // 	this.$router.push({
      // 	path: '/articleDetail?id=237',
      // })
      // }		
    }
  }
};